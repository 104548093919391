/* Calendar.css */
.calendar {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.calendar-header,
.calendar-body {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar-day-header,
.calendar-day,
.calendar-empty {
  padding: 10px;
  text-align: center;
  border: 1px solid #eee;
}

.calendar-day {
  position: relative;
  background-color: #f9f9f9;
}

.calendar-day:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.calendar-date {
  font-weight: bold;
}

.calendar-info {
  font-size: 0.8rem;
  margin-top: 5px;
}
